import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import LoadingSpinner from "./components/UI/LoadingSpinner";
import Layout from "./components/layout/Layout";
import AllQuotes from "./pages/AllQuotes";

/**
 * Lazy loading optimization
 * Il codice relativo a NewQuote viene scaricato solo quando si usa il componente NewQuote
 * Ma serve del tempo (non si sa quanto) pertanto va specificato un fallback da visualizzare nel mentre (Suspense)
 */
const NewQuote = React.lazy(() => import("./pages/NewQuote"));
const QuoteDetails = React.lazy(() => import("./pages/QuoteDetails"));
const NotFound = React.lazy(() => import("./pages/NotFound"));

function App() {
  return (
    <Layout>
      <Suspense
        fallback={
          <div className="centered">
            <LoadingSpinner />
          </div>
        }
      >
        <Route path="/" exact>
          <Redirect to="/quotes" />
        </Route>
        <Switch>
          <Route path="/quotes" exact>
            <AllQuotes />
          </Route>
          <Route path="/quotes/:quoteId">
            <QuoteDetails />
          </Route>
          <Route path="/new-quote">
            <NewQuote />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </Layout>
  );
}

export default App;
